import React, { Component } from 'react';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
// import { FullScreen, useFullScreenHandle } from "react-full-screen"

// import '../css/info_page.css';
// import { setCookie, getCookie } from '../cookie_utils.js'



class DisplayPage extends Component {

  render() {
 

        return (           
            <div id="body">
              <div className="display_layout" id="menu_bg">
                aaaaa
                
                
              </div>
            </div>
      
        );}
}

export default DisplayPage;
